import { template as template_c78f6a3ed3d94cd9af268180185913d7 } from "@ember/template-compiler";
const FKText = template_c78f6a3ed3d94cd9af268180185913d7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
